.navbar-main{
  background-color: rgb(0, 0, 80);
}


.nav-link{
  color: white;
}

.nav-link:hover{
  color: white;
  font-weight:bold;
}

.navbar-color{
  background-color: rgb(180, 68, 68);
}

.course-count{
  color:white;
  vertical-align: top;
  border-radius: 60%;
  background-color: #c67605;
  border: solid #c67605 1px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
}

.info-title{
    font-size: xx-large;
    text-align: center;
}

.section-title{
    font-size: large;
    color:darkblue;
}

.section-body{
    font-size:medium;
}